<template>
  <div class="queryDetails">
    <!-- 厂车预定 -->
    <van-nav-bar :left-text="name || $t('facCarBook.title')"
                 :title="checkFullDate"
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight">
      <template #right>
        <!-- 暂时不开发
          <van-checkbox v-model="mul" checked-color="#ee0a24">多选</van-checkbox> 
          -->
        <svg-icon icon-class="home_head_qrcode"></svg-icon>
      </template>
    </van-nav-bar>
    <div class="overflow_div">
      <!-- 查询条件--日期 -->
      <div class="date_content">
        <div class="date_box">
          <div class="head_date">
            <div class="icon_box"
                 @click="arrowDates('left')">
              <van-icon name="arrow-left" />
            </div>
            <div class="date_box"
                 v-for="(it, idx) in weeks"
                 :key="idx"
                 @click="getOneDay(it)">
              <div class="week">{{ it.week }}</div>
              <div class="date">
                <div class="text"
                     :class="
                    (mul && checkdatearr.indexOf(it.fulldate) > -1) ||
                    checkdate == it.date
                      ? 'bgtext'
                      : ''
                  ">
                  {{ it.date }}
                </div>
              </div>
            </div>
            <div class="icon_box"
                 @click="arrowDates('right')">
              <van-icon name="arrow" />
            </div>
            <div class="icon_box"
                 @click="showCalendar = true">
              <van-icon name="calendar-o" />
            </div>
          </div>
          <van-calendar v-model="showCalendar"
                        :min-date="new Date(2010, 0, 1)"
                        @confirm="onConfirm" />
        </div>
        <div class="table_content">
          <div v-for="(item, index) in carinfo"
               :key="index"
               :class="{
              car_card: true,
              'bus-b':
                bookinfo.go_car_id == item.car_id ||
                bookinfo.off_car_id == item.car_id,
              'bus-g':
                (item.go_booknum >= item.maxpeople &&
                  item.off_booknum >= item.maxpeople) ||
                !canchoose
            }">
            <div class="car_name"
                 @click="_getCarLineInfo(item)">
              <div class="name">{{ item.linename }}</div>
              <div class="line">{{ item.stationname }}[{{ item.carno }}]</div>
              <van-icon name="play" />
            </div>
            <div class="car_class">
              <div class="class_item"
                   v-show="item.go1">
                <div class="time">
                  {{ $t('facCarBook.go') }}（{{ item.go1 }}）
                </div>
                <div class="num">
                  {{ item.go_booknum }}/{{ item.maxpeople }}
                </div>
                <div :class="{
                    choose: true,
                    haschoose: bookinfo.go_car_id == item.car_id,
                    man: item.go_booknum >= item.maxpeople
                  }"
                     @click="setCarid(item, 'go')">
                  {{
                    bookinfo.go_car_id == item.car_id
                      ? $t('facCarBook.choose1')
                      : item.go_booknum >= item.maxpeople
                      ? $t('facCarBook.choose2')
                      : $t('facCarBook.choose3')
                  }}
                </div>
              </div>
              <div class="class_item"
                   v-show="item.off1">
                <div class="time">
                  {{ $t('facCarBook.off') }}（{{ item.off1 }}）
                </div>
                <div class="num">
                  {{ item.off_booknum }}/{{ item.maxpeople }}
                </div>
                <div :class="{
                    choose: true,
                    haschoose: bookinfo.off_car_id == item.car_id,
                    man: item.off_booknum >= item.maxpeople
                  }"
                     @click="setCarid(item, 'off')">
                  {{
                    bookinfo.off_car_id == item.car_id
                      ? $t('facCarBook.choose1')
                      : item.off_booknum >= item.maxpeople
                      ? $t('facCarBook.choose2')
                      : $t('facCarBook.choose3')
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="more">{{ $t('facCarBook.more') }}</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <van-button type="info"
                  size="small"
                  :disabled="!canOrder || !canchoose"
                  @click="_saveCarBookDayInfo">{{ $t('facCarBook.sumbit') }}</van-button>
      <p>{{ $t('facCarBook.last_book_time') }}{{ bookinfo.last_book_time }}</p>
    </div>
    <!-- 预约成功 -->
    <van-overlay :show="showSuc">
      <div class="box-suc">
        <div class="boxcontent">
          <img src="@/assets/img/success2.png" />

          <div class="bookline">{{ checkFullDate }}</div>
          <div class="bookline"
               v-if="goline">
            {{ $t('facCarBook.goline') }}{{ goline }}
          </div>
          <div class="bookline"
               v-if="offline">
            {{ $t('facCarBook.offline') }}{{ offline }}
          </div>
          <div>{{ $t('facCarBook.suc') }}</div>
          <van-button type="info"
                      size="small"
                      @click="showSuc = false">{{
            $t('facCarBook.ok')
          }}</van-button>
        </div>
      </div>
    </van-overlay>
    <!-- 二维码弹窗 -->
    <van-dialog class="facCarBook_QRcode_Dialog"
                v-model="showQRCode"
                show-cancel-button
                cancel-button-text="刷新二维码"
                confirm-button-text="取消"
                :before-close="closeCodeDia">
      <div class="content">
        <div class="QRCodecontent">
          <qrcode :text="QRCode"
                  :width="300"
                  :height="300" />
          <van-divider class="fuck1"
                       :hairline="false" />
          <div class="empinfo">
            <van-cell-group :border="false">
              <van-cell :title="$t('reqrcode.qrTxt8')"
                        :value="codeForm.empcode" />
              <van-cell :title="$t('reqrcode.qrTxt9')"
                        :value="codeForm.empname" />
              <van-divider class="fuck2"
                           :hairline="false" />
              <van-cell :title="codeForm.linename_go"
                        :value="codeForm.go_time" />
              <div class="station_box">{{codeForm.stationname_go}}</div>
              <van-cell :title="codeForm.linename_off"
                        :value="codeForm.off_time" />
              <div class="station_box">{{codeForm.stationname_off}}</div>
            </van-cell-group>
          </div>
          <van-divider class="fuck3"
                       :hairline="false" />
        </div>
      </div>
    </van-dialog>

    <van-overlay :show="showLine"
                 @click="showLine = false">
      <div class="box-suc">
        <div class="linecontent">
          <div class="linetitle">{{ linetitle }}</div>
          <div class="linebox">
            <div class="lineitem"
                 v-for="(item, i) in lineData"
                 :key="i">
              <span>{{ item.stationname
                }}{{
                  i == 0
                    ? $t('facCarBook.begin')
                    : i == lineData.length - 1
                    ? $t('facCarBook.end')
                    : ''
                }}</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { parseTime } from '@/utils'
import {
  getCarBookDayInfo,
  getCarLineInfo,
  saveCarBookDayInfo,
  getEmpInfoByEmpCode,
  saveBarCodeRecord,
  getEmpBarCodeInfo
} from '@api/wx.js'
import qrcode from '@components/qrcode'
export default {
  components: {
    qrcode
  },
  data () {
    const fromH5 = this.$route.query.fromH5
    const name = this.$route.query.name
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    const param = this.$route.query.param
    return {
      userInfo,
      param, // 从外部过来的参数
      fromH5, // 是否从H5过来的
      name, //传入的标题
      years: [], // 年份数组
      checkedYear: '', // 选中的年份
      CloundEntid: window.CloundEntid || '',
      weeks: [],
      checkdate: '', // 选中的日期（dd）
      checkFullDate: '', // 选中的日期（yyMMdd）
      showCalendar: false, // 日历组件
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      showSuc: false, // 预约成功弹窗
      showQRCode: false, // 二维码弹窗
      showLine: false, // 路线弹窗
      loading: false,
      empid: 0, // 当前登录的empid
      langid: 0, // 当前多语言Id
      empinfo: {},
      bookinfo: {
        autoid: 0,
        empid: 0,
        go_car_id: null,
        last_book_time: '20:00',
        off_car_id: null,
        yymmdd: '2023-12-22'
      },
      inibookinfo: {
        autoid: 0,
        empid: 0,
        go_car_id: null,
        last_book_time: '20:00',
        off_car_id: null,
        yymmdd: '2023-12-22'
      },
      carinfo: [],
      outtime: window.eastriver_barcode_outtime
        ? window.eastriver_barcode_outtime
        : 120,
      recommendUrl: window.eastriver_barcode_url
        ? window.eastriver_barcode_url
        : 'https://hyclound.cn:8019/EastRiver/QRCode',
      QRCode: '',
      lineData: [], // 详细线路
      showCalendar: false,
      mul: false, // 多选日期
      linetitle: '',
      checkdatearr: [],
      codeForm: {
        empcode: "",
        empid: 0,
        empname: "",
        idno: "",
        linename_go: "",
        go_time: "",
        stationname_go: "",
        linename_off: "",
        off_time: "",
        stationname_off: ""
      }
    }
  },
  computed: {
    goline () {
      let l = ''
      if (this.bookinfo.go_car_id)
      {
        this.carinfo.forEach(item => {
          if (item.car_id == this.bookinfo.go_car_id)
            l = `${item.linename} ${item.stationname} [${item.carno}]`
        })
      }
      return l
    },
    offline () {
      let l = ''
      if (this.bookinfo.off_car_id)
      {
        this.carinfo.forEach(item => {
          if (item.car_id == this.bookinfo.off_car_id)
            l = `${item.linename} ${item.stationname} [${item.carno}]`
        })
      }
      return l
    },
    // 是否有修改所选
    canOrder () {
      return !(
        this.bookinfo.go_car_id === this.inibookinfo.go_car_id &&
        this.bookinfo.off_car_id === this.inibookinfo.off_car_id
      )
    },
    // 过期无法预约
    canchoose () {
      let b = false
      // 今日之后
      if (
        this.bookinfo.yymmdd >
        parseTime(
          new Date(),
          // new Date(new Date().toLocaleDateString()).getTime() +
          //   24 * 60 * 60 * 1000
          '{y}-{m}-{d}'
        )
      )
        b = true
      // 今日之后
      else if (
        this.bookinfo.yymmdd ==
        parseTime(
          new Date(),
          // new Date(new Date().toLocaleDateString()).getTime() +
          //   24 * 60 * 60 * 1000
          '{y}-{m}-{d}'
        ) &&
        this.bookinfo.last_book_time > parseTime(new Date(), '{h}:{i}')
      )
        b = true
      return b
    }
  },
  created () {
    // 根据当前日期获取本周日期
    this.getMonDayAndSunDay(new Date())
    // 获取年
    this.getYearsByDate(new Date())
  },
  watch: {
    mul (n) {
      if (n)
      {
        this.checkdatearr = [this.checkFullDate]
      }
    }
  },
  methods: {
    closeCodeDia (action, done) {
      if (action == 'confirm')
      {
        this.showQRCode = false
        done()
      } else
      {
        done(false)
        this.getQRCode()
      }
    },
    _getCarLineInfo (item) {
      this.linetitle = `${item.linename} ${item.stationname} [${item.carno}]`
      getCarLineInfo({
        car_id: item.car_id,
        yymmdd: this.bookinfo.yymmdd,
        username: this.userInfo.username
      }).then(res => {
        this.lineData = res.data
        this.showLine = true
      })
    },
    _saveCarBookDayInfo () {
      const { empid, yymmdd, go_car_id, off_car_id } = this.bookinfo
      saveCarBookDayInfo({
        empid,
        yymmdd,
        go_car_id,
        off_car_id
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(this.$t('facCarBook.fail') + res.data[0].info)
        } else
        {
          //预约成功！
          this.showSuc = true
        }
        // 查询页面
        this.getAllPageDatas(yymmdd)
      })
    },
    setCarid (item, type) {
      if (this.canchoose)
      {
        if (type == 'go' && item.go_booknum < item.maxpeople)
        {
          this.bookinfo.go_car_id =
            this.bookinfo.go_car_id != item.car_id ? item.car_id : null
        } else if (type == 'off' && item.off_booknum < item.maxpeople)
        {
          this.bookinfo.off_car_id =
            this.bookinfo.off_car_id != item.car_id ? item.car_id : null
        }
      }
    },
    // 根据某一天获取所在周的日期
    getMonDayAndSunDay (date) {
      let blank = [
        { week: '周一', date: '', fulldate: null },
        { week: '周二', date: '', fulldate: null },
        { week: '周三', date: '', fulldate: null },
        { week: '周四', date: '', fulldate: null },
        { week: '周五', date: '', fulldate: null },
        { week: '周六', date: '', fulldate: null },
        { week: '周日', date: '', fulldate: null }
      ]
      blank.forEach((el, idx) => {
        let fulldate = null
        if (new Date(date).getDay() > 0)
        {
          // 大于0表示不是星期日
          fulldate = new Date(
            new Date(date).getTime() -
            3600 * 1000 * 24 * (new Date(date).getDay() - (idx + 1))
          )
        } else
        {
          // 这是星期日
          fulldate = new Date(
            new Date(date).getTime() -
            3600 * 1000 * 24 * (new Date(date).getDay() - (idx - 6))
          )
        }
        el.fulldate = this.formatDate(fulldate, 'yyyy-MM-dd')
        let da = fulldate.getDate()
        if (da < 10)
        {
          da = '0' + da
        }
        el.date = da
      })
      this.weeks = blank
      this.checkdate = new Date(date).getDate()
      if (this.checkdate < 10)
      {
        this.checkdate = '0' + this.checkdate
      }
      this.checkFullDate = this.formatDate(new Date(date), 'yyyy-MM-dd')
      this.param = this.formatDate(this.checkFullDate, 'yyyy-MM-dd')
      // 查询页面
      this.getAllPageDatas(this.param)
    },
    // 根据日期获取年份列表
    getYearsByDate (v) {
      if (v)
      {
        let date = new Date(v)
        let year = date.getFullYear()
        this.years = [year - 1, year, year + 1]
        //
        this.checkedYear = year
      }
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')
        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else
        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else
      {
        return ''
      }
    },
    // 返回
    onClickLeft () {
      if (this.$route.query.from == 'home')
      {
        this.$router.push('/home')
      } else
      {
        this.$router.push('/personCen')
      }
    },
    // 点击导航右按钮
    onClickRight () {
      getEmpBarCodeInfo({
        empcode: this.userInfo.empcode
      }).then(res => {
        this.idno = res.data[0].idno
        this.codeForm = { ...this.codeForm, ...res.data[0] }
        this.b4getQRCode()
      })
    },
    b4getQRCode () {
      saveBarCodeRecord({
        empid: this.userInfo.empid,
        outtime: this.outtime
      }).then(res => {
        this.getQRCode()
      })
    },
    getQRCode () {
      // 创建XML请求
      var ajaxObj = new XMLHttpRequest()
      // 以get形式进行发送请求
      ajaxObj.open(
        'get',
        this.recommendUrl +
        '?CardSn=' +
        this.idno +
        '&DesKey=12345678&Des=0&outtime=' +
        this.outtime
      )
      // 请求结束断开
      ajaxObj.send()
      // 初始化变量（必要）
      // 转入： this转为function（）方法内部函数（重点）
      const that = this
      ajaxObj.onreadystatechange = function () {
        if (ajaxObj.readyState === 4 && ajaxObj.status === 200)
        {
          // 请求放回的结果以文本形式，使用JSON.parse()方法进行转为json形式
          var data = JSON.parse(ajaxObj.responseText)
          console.log('数据返回成功', ajaxObj.responseText)
          if (data.error == '0')
          {
            that.QRCode = data.QRCode
            that.showQRCode = true
          } else
          {
            Notify({ type: 'danger', message: data.msg })
          }
        }
      }
    },
    // 点击选择某个年份
    getOneYear (v) {
      this.checkFullDate = v + this.checkFullDate.substring(4)
      this.param = this.formatDate(this.checkFullDate, 'yyyy-MM-dd')
      // 查询页面
      this.getAllPageDatas(this.param)
    },
    // 箭头切换年份
    arrowsYear (v) {
      if (v == 'left')
      {
        this.years.unshift(this.years[0] - 1)
        this.years.pop()
      } else
      {
        this.years.push(this.years[2] + 1)
        this.years.shift()
      }
    },
    // 左右切换周
    arrowDates (val) {
      if (val == 'left')
      {
        // 根据当前weeks里的第一天获取上周日期
        let preDate = new Date(
          new Date(this.weeks[0].fulldate).getTime() - 24 * 60 * 60 * 1000
        ) //前一天
        this.getMonDayAndSunDay(this.formatDate(preDate, 'yyyy-MM-dd'))
      } else if (val == 'right')
      {
        // 根据当前weeks里的最后一天获取下周日期
        let nextDate = new Date(
          new Date(this.weeks[6].fulldate).getTime() + 24 * 60 * 60 * 1000
        ) //后一天
        this.getMonDayAndSunDay(this.formatDate(nextDate, 'yyyy-MM-dd'))
      }
      // 查询某一天数据
      let isToday = false
      let todayIdx = 0
      for (let i = 0; i < this.weeks.length; i++)
      {
        const it = this.weeks[i]
        if (it.fulldate == this.formatDate(new Date(), 'yyyy-MM-dd'))
        {
          isToday = true
          todayIdx = i
        }
      }
      // 如果是当前日期就选当前日期，否则选择一周的星期一
      if (isToday)
      {
        this.getOneDay(this.weeks[todayIdx])
      } else
      {
        this.getOneDay(this.weeks[0])
      }
    },
    // 选择某一天
    getOneDay (it) {
      if (this.mul)
      {
        const index = this.checkdatearr.indexOf(it.fulldate)
        if (index == -1)
        {
          this.checkdatearr.push(it.fulldate)
        } else
        {
          this.checkdatearr.splice(index, 1) // 删除一天
        }
      } else
      {
        this.checkdate = it.date
        this.checkFullDate = it.fulldate
        this.param = this.formatDate(this.checkFullDate, 'yyyy-MM-dd')
        // 查询页面
        this.getAllPageDatas(this.param)
      }
    },
    // 获取页面所有信息
    getAllPageDatas (yymmdd) {
      this.carinfo = []
      getCarBookDayInfo({
        empid: this.userInfo.empid,
        yymmdd,
        langid: this.langid
      }).then(res => {
        this.bookinfo = res.bookinfo[0]
        this.inibookinfo = JSON.parse(JSON.stringify(res.bookinfo[0]))
        this.carinfo = res.carinfo
      })
    },
    onConfirm (value) {
      this.showCalendar = false
      this.getMonDayAndSunDay(value)
    }
  }
}
</script>

<style scoped lang="less">
.facCarBook_QRcode_Dialog {
  height: auto;
  // height: 80% !important;
  width: 90% !important;
  padding: 10px 20px !important;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #c2e3ff 0%, #ffffff 100%);
  box-shadow: 0px 3px 6px 0px rgba(82, 155, 178, 0.16);
  /deep/.van-dialog__content {
    height: calc(100% - 100px);
    .content {
      height: 100%;
      overflow: auto;
      padding-top: 40px;
      .QRCodecontent {
        .van-divider {
          border-color: #969799;
        }
        .fuck1 {
          margin: 30px 30px 6px 30px !important;
        }
        .fuck2 {
          margin: 6px 30px !important;
        }
        .fuck3 {
          margin: 6px 30px 0 30px !important;
        }
        .empinfo {
          .van-cell-group {
            width: 100%;
            background: none;
            .van-cell {
              padding: 6px 30px !important;
              background: unset !important;
            }
            .station_box {
              padding: 6px 30px !important;
              font-size: 24px;
              color: #969799;
            }
          }
        }
      }
    }
  }

  /deep/.van-dialog__footer {
    height: 100px !important;
  }
}
.queryDetails {
  height: 100vh;
  overflow: hidden;
  // background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
      .van-checkbox {
        margin-right: 20px;

        .van-checkbox__label {
          color: #fff;
        }
      }
    }
  }
  .overflow_div {
    background: #f6f6f6;
    // overflow: auto;
    height: calc(100% - 100px);
    .date_content {
      .year_box {
        display: flex;
        align-items: center;
        padding: 20px 0;
        background: #ffffff;
        .icon_box {
          display: flex;
          align-items: center;
          .van-icon {
            font-size: 40px;
            font-weight: bold;
          }
        }
        .year_btn {
          width: calc((100% - 40px) / 3);
          color: #2a2a2a;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          background-color: #eff6ff;
          border-radius: 10px;
          .year {
            padding: 10px 0;
          }
        }
        .cao0 {
          margin: 0 20px 0 10px;
        }
        .cao1 {
          margin-right: 20px;
        }
        .cao2 {
          margin-right: 10px;
        }
        .checkd_year {
          color: #ffffff;
          background: #5398ff;
        }
      }
      .month_box {
        display: flex;
        flex-wrap: wrap;
        background: #ffffff;
        padding-bottom: 10px;
        .month_btn {
          width: calc(100% / 6);
          color: #aeaeae;
          font-size: 28px;
          text-align: center;
          .month {
            padding: 10px 0;
            margin: 10px;
          }
          .checkd_Month {
            color: #5095ff;
            background: #ffffff;
            border-radius: 30px;
            border: 1px solid #5095ff;
          }
        }
      }
      .date_box {
        .head_date {
          display: flex;
          align-items: center;
          padding: 20px 0;
          background: #fff;
          .icon_box {
            display: flex;
            align-items: center;
            .van-icon {
              font-size: 40px;
              font-weight: bold;
            }
          }
          .date_box {
            width: calc((100% - 40px) / 7);
            font-size: 28px;
            text-align: center;
            .week {
              margin-bottom: 10px;
            }
            .date {
              display: flex;
              justify-content: center;
              align-items: center;
              .text {
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 50%;
              }
              .bgtext {
                color: #fff;
                background: #2b8df0;
              }
            }
          }
        }
      }
    }
    .table_content {
      position: relative;
      font-size: 28px;
      height: calc(100vh - 500px);
      overflow-y: auto;
      .more {
        text-align: center;
        color: #333333;
      }
      .car_card {
        height: 300px;
        background: center center/100% 100%;
        padding: 30px;
        background-image: url("~@/assets/img/bus-w.png");
        .car_name {
          display: flex;
          align-items: center;
          justify-content: start;
          .name {
            font-size: 36px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
          }
          .line {
            font-weight: 400;
            color: #333333;
            padding: 0 20px;
          }
        }
        .car_class {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          justify-content: space-around;
          height: calc(100% - 40px);
          .class_item {
            display: flex;
            align-items: center;
            justify-content: start;
            .num {
              font-size: 36px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              color: #2b8df0;
              padding: 0 20px;
            }
            .choose {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 130px;
              height: 50px;
              background: #ededed;
              border-radius: 25px;
              cursor: pointer;
            }
            .choose.haschoose {
              background: #ebf6ff;
              color: #2b8df0;
            }
            .choose.man {
              color: #ccc;
            }
          }
        }
      }
      .bus-g {
        background-image: url("~@/assets/img/bus-g.png");
        .car_class {
          .class_item {
            .choose,
            .num {
              color: #ccc;
            }
          }
        }
      }
      .bus-b {
        background-image: url("~@/assets/img/bus-b.png");
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    .van-button {
      width: 95%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
  .box-suc {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .boxcontent {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      width: 80vw;
      height: 60vh;
      padding: 10vh 0 10vh;
      background: linear-gradient(180deg, #c2e3ff 0%, #ffffff 100%);
      box-shadow: 0px 3px 6px 0px rgba(82, 155, 178, 0.16);
      border-radius: 20px;
      img {
        margin-left: 5vw;
      }
      .bookline {
        font-size: 26px;
      }
      .van-button {
        width: 55%;
        background: #63b0ff;
        border-radius: 20px;
        .van-button__text {
          font-size: 36px;
          font-family: Source Han Sans SC;
          font-weight: 500;
        }
      }
    }
    .QRCodecontent {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      width: 90%;
      // height: 80%;
      height: calc(100% - 1.2rem);
      background: linear-gradient(180deg, #c2e3ff 0%, #ffffff 100%);
      box-shadow: 0px 3px 6px 0px rgba(82, 155, 178, 0.16);
      border-radius: 20px;
    }
    .empinfo {
      width: 90%;
      .van-cell-group {
        width: 100%;
        background: none;
        .van-cell {
          background: unset !important;
          // border-radius: 30px;
          // margin: 10px 0;
        }
      }
    }
    .close {
      width: 90px;
      height: 90px;
      background: #999999;
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
    .linecontent {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      width: 80vw;
      height: 80vh;
      background: linear-gradient(180deg, #c2e3ff 0%, #ffffff 100%);

      box-shadow: 0px 3px 6px 0px rgba(82, 155, 178, 0.16);
      border-radius: 20px;
      .linetitle {
        font-size: 36px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin: 20px 0;
      }
      .linebox {
        background: url("~@/assets/img/dw_gongjiao.png") right bottom no-repeat;
        width: 100%;
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
        height: calc(100% - 80px);
        padding: 20px;
        overflow-y: auto;
        .lineitem {
          padding-left: 50px;
          margin-left: 50px;
          background: url("~@/assets/img/line3.png") left top no-repeat;
          height: 50px;
          line-height: 50px;
          position: relative;
          span {
            position: absolute;
            top: -15px;
          }
        }
        .lineitem:first-child {
          background: url("~@/assets/img/line1.png") left top no-repeat;
          font-weight: 500;
          color: #2b8df0;
        }
        .lineitem:last-child {
          background: url("~@/assets/img/line2.png") left -75px no-repeat;
          font-weight: 500;
          color: #2b8df0;
        }
      }
    }
  }
}
</style>
